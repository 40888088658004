/*
* leverancier.ts
* 9-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*
* Leverancier uit exact
*/

import {Basemodel, ModelField} from './basemodel';

export class Leverancier extends Basemodel {
  static table = 'leverancier';
  static defaultOrder = ['naam'];
  @ModelField() id: number;
  @ModelField() naam: string;
  @ModelField() werkmaatschappij_id: number;
  @ModelField() leverancierstype: 'Inhuur' | 'ZZP Poule';
  @ModelField() exact_id: string = null;

  public get bron(): 'Exact'|'ATRPlan' {
    return this.exact_id ? 'Exact' : 'ATRPlan';
  }
}
