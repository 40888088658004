/*
* werkbonnenlijst.ts
* 1-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Component, Injector, ViewChild} from '@angular/core';
import {Datatable2scherm} from '../abstracts/datatable2scherm.class';
import {PersoneelPlanitem} from '../../models/personeel_planitem';
import {Werkbon} from '../../models/werkbon';
import {DatatableDef, SihwDatatable} from 'sihw-ng/datatable/datatable.component';
import {Planitem} from '../../models/planitem';
import {Project} from '../../models/project';
import * as moment from 'moment';
import {PlanitemFunction} from '../../models/planitem_function';
import {Functie} from '../../models/functie';
import {Personeel} from '../../models/personeel';
import {Leverancier} from '../../models/leverancier';
import {inkooporder} from '../../app/types';
import {Werkmaatschappij} from '../../models/werkmaatschappij';

@Component({
  selector: 'werkbonnenlijst',
  templateUrl: './werkbonnenlijst.html',
  styleUrls: ['./werkbonnenlijst.scss']
})
export class Werkbonnenlijstscherm extends Datatable2scherm {
  _starttitle = 'Werkbonnen';
  icon = 'fas fa-file-signature';
  model = PersoneelPlanitem; //geen query maar een eigen dataGetter
  subscribeDatachanges = false; //gewoon niet
  fixedWidth = '90vw'; //om te beginnen
  openMaximized = true;

  //bereik
  datumBereikLijst: any[] = [ //aantal dagen
    {weken: 1, waarde: '1 week'},
    {weken: 4, waarde: '4 weken'},
    {weken: 13, waarde: '3 maanden'},
    {weken: 26, waarde: 'Half jaar'},
    {weken: 52, waarde: 'Jaar'},
    {weken: 104, waarde: '2 jaar'},
    {weken: -1, waarde: 'Alles (duurt lang...)'},
  ];
  datumbereik: number = parseInt(localStorage['werkbonnen_datumbereik'] || 4,10);


  public dataGewijzigd: boolean = false; //we poppen de herlaaddata-knop op als dat nodig is
  @ViewChild('datatable') datatable: SihwDatatable;
  tableDefinition: DatatableDef = {
    track: 'id',
    standaardsortering: {
      key: 'datum',
      richting: 1
    },
    selecteer: {
      label: 'Selecteer'
    },
    rijclasses: (ppi: PersoneelPlanitem) => {
      let classes = [];
      if (!(ppi.getekend || (ppi.werkbon?.proforma))) {
        classes.push('missend');
      } else if (ppi.afwijkend || ppi.werkbon.proforma) {
        classes.push('afwijkend');
      } else {
        classes.push('ok');
      }
      //extra classes voor selectieknoppen
      if ((!ppi.ingekocht) && ppi.werkbon && ppi.personeel.groep !== 'Personeel') {
        classes.push('inkoop');
      }
      return classes;
    },
    velden: {
      weeknummer: {
        groep: 'Dienst',
        prop: '_owner.startdate',
        label: 'Week',
        type: ['moment', {format: 'YYYY-WW'}]
      },

      datum: {
        groep: 'Dienst',
        prop: '_owner.startdate',
        label: 'Datum',
        type: ['moment', {format: 'DD-MM-YY'}],
      },
      plantijden: {
        groep: 'Dienst',
        label: 'Gepland',
        type: 'format',
        format: item => item?._owner ? `${item._owner.starttime} - ${item._owner.endtime}` : ''
      },
      werkmaatschappij: {
        hide: this.api.userdata.werkmaatschappij.length === 1, //niet afbeelden als er maar 1 is
        groep: 'Dienst',
        label: 'WM',
        type: 'enum',
        prop: '_owner._tags.werkmaatschappij.afkorting'
      },
      klant: {
        groep: 'Dienst',
        label: 'Klant',
        type: 'enum',
        prop: '_owner._owner.klantnaam'
      },
      project: {
        groep: 'Dienst',
        label: 'Project',
        type: 'enum',
        prop: '_owner._owner.label'
      },
      functie: {
        groep: 'Dienst',
        label: 'Functie',
        type: 'enum',
        prop: '_owner.functionlabel'
      },

      status: {
        groep: 'Werkbon',
        label: 'Status',
        type: 'enum',
        prop: 'werkbon.status',
        format: (status: string | undefined, ppi: PersoneelPlanitem) => {
          if (!status) {
            return 'Ontbreekt';
          }
          if (status === 'ingediend') {
            return ppi.werkbon.proforma ? 'Ongetekend' : 'Getekend';
          }
          return status;
        }
      },
      createdAt: {
        groep: 'Werkbon',
        label: 'Getekend',
        prop: 'werkbon.createdAt',
        type: ['moment', {format: 'DD-MM-YY'}]
      },
      tijden: {
        groep: 'Werkbon',
        label: 'Werktijden',
        type: 'format',
        format: rij => rij.werkbon ? `${rij.werkbon.starttijd} - ${rij.werkbon.eindtijd}` : ''
      },
      kilometers: {
        groep: 'Werkbon',
        label: 'KM Project',
        type: 'int',
        prop: 'werkbon.kilometers'
      },
      kilometers_ww: {
        groep: 'Werkbon',
        label: 'KM ww',
        type: 'int',
        prop: 'werkbon.kilometers_ww'
      },
      pauze: {
        groep: 'Werkbon',
        label: 'Pauze',
        type: 'int',
        prop: 'werkbon.pauze'
      },
      personeel: {
        label: 'Personeel',
        groep: 'Personeel',
        type: 'enum',
        prop: 'personeel.naam'
      },

      groep: {
        label: 'Groep',
        groep: 'Personeel',
        type: 'enum',
        prop: 'personeel.groep'
      },

      leverancier: {
        label: 'Leverancier',
        prop: 'leverancier.naam',
        groep: 'Personeel',
        type: 'enum',
        format: (item, rec) => rec.personeel.groep === 'Personeel' ? '-' : (item || '-')
      },
      ingekocht: {
        label: 'Inkooporder',
        groep: 'Exact',
        type: 'bool'
      },
      inkooporder: {
        groep: 'Exact',
        label: 'Bestelnr',
        type: 'int'
      },
      ingediend: {
        groep: 'Exact',
        label: 'Ingediend',
        type: 'bool',
        standaardfilter: 2
      }

    }
  };

  //constructor ivm injection
  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  /**
   * True als er iets is geselecteerd waarvoor we een proforma werkbon kunnen maken
   */
  public get kanProforma(): boolean {
    return this.datatable?.geselecteerd.some((ppi: PersoneelPlanitem) => !ppi.werkbon);
  }

  /**
   * True als er iets is geselecteerd waarvoor we een inkooporder kunnen maken
   */
  public get kanInkoopOrder(): boolean {
    return this.datatable?.geselecteerd.some((ppi: PersoneelPlanitem) => ppi.personeel.groep !== 'Personeel' && ppi.leverancier && (!ppi.ingekocht) && ppi.werkbon);
  }

  /**
   * True als er iets is geselecteerd waarvoor we een werkbonpdf kunnen maken
   */
  public get kanWerkbonpdf(): boolean {
    return this.datatable?.geselecteerd.some((ppi: PersoneelPlanitem) => ppi.werkbon);
  }

  public get kanIndienen(): boolean {
    return this.datatable?.geselecteerd.some((ppi: PersoneelPlanitem) => (!ppi.ingediend));
  }

  /**
   * Eigen datagetter ivm throughOwner voor het bijbehorende planitem
   */
  async dataGetter(): Promise<PersoneelPlanitem[]> {
    //we halen het via project en planitem
    let items = await Planitem.getAll(
      [PlanitemFunction, PersoneelPlanitem, Werkbon],
      [
        [
          Planitem,
          {
            where: {
              startdate: {
                $lt: moment().format('YYYY-MM-DD'),
                $gt: this.datumbereik === -1 ? undefined : moment().subtract(this.datumbereik || 4, 'week').startOf('week').format('YYYY-MM-DD')
              }
            }
          }
        ],
        [
          PersoneelPlanitem,
          {
            required: true //alleen projecten en planitems met personeel
          }
        ],
        [
          Werkbon,
          {
            attributes: ['id', 'status', 'starttijd', 'eindtijd', 'pauze', 'kilometers', 'kilometers_ww', 'opmerkingen', 'proforma', 'createdAt'],
            required: false //we willlen ook weten wanneer het mis
          }
        ],
        [
          Project,
          {
            where: {
              factureerbaar: true
            }
          }
        ]
      ],
      null,
      null,
      Project //via deze owner
    );
    this.dataGewijzigd = false; //we zijn weer bij
    this.log.debug(`Planitems`, items);
    //lookups toevoegen
    let functies = await Functie.getLookup(Planitem);
    let personeel = await Personeel.getLookup(Planitem);
    let leveranciers = await Leverancier.getLookup(Planitem);
    let werkmaatschappijen = await Werkmaatschappij.getLookup(Planitem);
    //dit is veel te veel om het goed te laten verlopen. Duurt seconden en seconden
    for (let item of items) {
      //functies erin
      item.setFunctionlabel(functies);
      item.setPersoneel(personeel); //kost weinig tijd, loggen ervan kost veel tijd
      item.setLeveranciers(leveranciers);
      item._tags.werkmaatschappij = werkmaatschappijen[item.project.werkmaatschappij_id];
    }
    //platslaan
    this.log.debug(`Data uitgerekend`);
    return [].concat(...items.map(item => item.personeel_planitem));
  }

  /**
   * Reloadknop, omdat we niet aan subscriptions doen
   */
  public async reloadData() {
    return this.planHaalData();
  }

  /**
   * Wijziging in datumbereik: opslaan en reloaden
   */
  public async switchPeriode() {
    localStorage['werkbonnen_datumbereik'] = this.datumbereik;
    await this.reloadData();
  }
  /**
   * Button: regel het maken van proforma werkbonnen via het backend
   * UX heeft can(...) geregeld
   */
  public async maakProforma() {
    if (!this.kanProforma) {
      return; //niets te doen
    }
    let items = <PersoneelPlanitem[]> this.datatable.geselecteerd.filter((ppi: PersoneelPlanitem) => !ppi.werkbon); //Alle items in selectie zonder werkbon
    if (!await this.api.confirm('Aanmaken proforma werkbonnen', `We maken ${items.length} ongetekende (proforma) ${items.length === 1 ? 'werkbon' : 'werkbonnen'} aan. Wil je doorgaan?`)) {
      return;
    }

    //we gaan loopen
    let aantal = 0;
    for (let item of items) {
      let werkbon = new Werkbon;
      werkbon.personeelvast_planitem_id = item.id;
      werkbon.starttijd = item.planitem.starttime;
      werkbon.eindtijd = item.planitem.endtime;
      werkbon.proforma = true;
      // werkbon.opmerkingen = 'Gegeneerd als proforma-werkbon';
      await werkbon.save();
      aantal++;
    }
    this.api.toast((aantal === 0 || aantal > 1) ? `Er zijn ${aantal} werkbonnen gemaakt` : `De werkbon is gemaakt`, 'success');
    this.log.debug(`Bonnen gemaakt. Reloaden`);
    await this.reloadData();
    this.datatable.selecteerRijen([]);
  }

  /**
   * Button: regel de inkooporders via het backend. Wij sorteren voor op de juiste selectie
   */
  public async doeInkoop() {
    if (!this.kanInkoopOrder) {
      return; //niets te doen
    }
    let items = <PersoneelPlanitem[]> this.datatable.geselecteerd.filter((ppi: PersoneelPlanitem) => ppi.leverancier && (!ppi.ingekocht) && ppi.werkbon); //werkbon moet er zijn, maar mag proforma zijn
    //deze werkbonnen moeten. Per leverancier.
    let orders: inkooporder[] = [];
    let leveranciers: number[] = [], weken: string[] = []; //voor de statistieken
    for (let item of items) {
      let weeknummer = moment(item.planitem.startdate).format('YYYY-WW');
      if (!weken.includes(weeknummer)) {
        weken.push(weeknummer);
      }
      if (!leveranciers.includes(item.leverancier_id)) {
        leveranciers.push(item.leverancier_id);
      }
      let inkoop: inkooporder = orders.find(order => order.leverancier_id === item.leverancier_id && order.weeknummer === weeknummer);
      if (!inkoop) {
        inkoop = {
          leverancier_id: item.leverancier_id,
          weeknummer: weeknummer,
          ppi: []
        };
        orders.push(inkoop);
      }
      //voeg dit item toe
      inkoop.ppi.push(item.id);
    }

    let skipwaarschuwing: string = '';
    const geskipt = this.datatable.selectieRijen - items.length;
    if (geskipt) {
      //blijkbaar gaan een paar niet
      skipwaarschuwing = ` Voor ${geskipt} geselecteerde ${geskipt === 1 ? 'rij' : 'rijen'} kunnen we geen inkooporder maken.`;
    }
    if (!await this.api.confirm('Aanmaken inkooporders', `We maken ${orders.length} ${orders.length === 1 ? 'inkooporder' : 'inkooporders'}, voor in totaal ${weken.length} ${weken.length === 1 ? 'week' : 'weken'} en ${leveranciers.length} ${leveranciers.length === 1 ? 'leverancier' : 'leveranciers'}.${skipwaarschuwing} Wil je doorgaan?`)) {
      return;
    }
    this.log.debug(orders);
    await this.api.maakInkooporders(orders); //regelt het verder wel inclusief feedback
    this.log.debug(`Inkoop gemaakt. Reloaden`);
    await this.reloadData();
  }

  /**
   * POC: regel een pdf met werkbonnen voor de geselecteerde items
   */
  public async werkbonPDF() {
    if (!this.kanWerkbonpdf) {
      return; //niets te doen
    }
    let items = (<PersoneelPlanitem[]> this.datatable.geselecteerd).filter(ppi => ppi.werkbon).map(ppi => ppi.id); //alleen de items met werkbon, getekend of niet
    //POC
    await this.api.maakWerkbonPDF(items);
  }

  /**
   * Dien de uren voor de geselecteerde regels in in exact
   */
  public async doeIndienen() {
    if (!this.kanIndienen) {
      return;
    }
    let items = <PersoneelPlanitem[]> this.datatable.geselecteerd.filter((ppi: PersoneelPlanitem) => (!ppi.ingediend));
    //nog niet ingediend
    let indienen: number[] = []; //de id's van de PPIs
    let projecten: number[] = [], zonderwerkbon: number = 0, proformawerkbon: number = 0; //statistieken
    for (let item of items) {
      let project: Project = item.planitem.project;
      if (!project) {
        continue; //kan niet voorkomen
      }
      if (!projecten.includes(project.id)) {
        projecten.push(project.id);
      }
      if (!item.werkbon) {
        zonderwerkbon++;
      } else if (!item.getekend) {
        proformawerkbon++;
      }
      indienen.push(item.id); //deze gaat naar achteren
    }
    let skipwaarschuwing: string = '';
    const geskipt = this.datatable.selectieRijen - indienen.length;
    if (geskipt) {
      //blijkbaar gaan een paar niet
      skipwaarschuwing = ` Voor ${geskipt} geselecteerde ${geskipt === 1 ? 'rij' : 'rijen'} kunnen we geen uren indienen.`;
    }
    let zonderwerkbonwaarschuwing: string = '';
    if (zonderwerkbon) {
      zonderwerkbonwaarschuwing = ` Let op. Voor ${zonderwerkbon} ${zonderwerkbon === 1 ? 'regel' : 'regels'} is er geen werkbon beschikbaar.`;
    }
    let proformawaarschuwing: string = '';
    if (proformawerkbon) {
      proformawaarschuwing = ` Let op. Voor ${proformawerkbon} ${proformawerkbon === 1 ? 'regel' : 'regels'} is er alleen een ongetekende werkbon beschikbaar.`;
    }
    if (!await this.api.confirm('Indienen uren', `We dienen ${indienen.length} ${indienen.length === 1 ? 'regel' : 'regels'} voor  ${projecten.length} ${projecten.length === 1 ? 'project' : 'projecten'} in.${skipwaarschuwing}${proformawaarschuwing}${zonderwerkbonwaarschuwing} Wil je doorgaan?`)) {
      return;
    }
    this.log.debug(indienen);
    await this.api.urenIndienen(indienen); //doet ook de feedback
    //opnieuw laden, als het goed is, is er iets gewijzigd
    await this.reloadData();
    //nu mag de selectie wel weg
    this.datatable.selecteerRijen([]);
  }

  /**
   * Overrule de init: we doen niets, maar tonen alleen een knopje
   * @protected
   */
  protected initDatawijzigsubscription(): void {
    this.subscription(this.api.onDatawijziging([Project, Planitem, PersoneelPlanitem, Werkbon, PlanitemFunction, Functie, Leverancier]).subscribe(() => {
      this.dataGewijzigd = true;
    }));
  }
}
