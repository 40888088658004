/*
* leveranciers.ts
* 9-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Component, Injector} from '@angular/core';
import {Datatablescherm} from '../abstracts/datatablescherm.class';
import {Leverancier} from '../../models/leverancier';
import {DatatableDef} from 'sihw-ng/datatable/datatable.component';
import {Project} from '../../models/project';
import {hash} from '../../app/types';
import {Werkmaatschappij} from '../../models/werkmaatschappij';

@Component({
  selector: 'leverancierlijst',
  templateUrl: './leverancierlijst.html'
})
export class Leverancierlijstscherm extends Datatablescherm {
  _starttitle = 'Leveranciers';
  icon = 'fas fa-truck';
  model = Leverancier;

  werkmaatschappijen: hash<Werkmaatschappij> = {};

  tableQuery = Leverancier.recordquery([], []);

  //constructor ivm injection
  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  async dataGetter(): Promise<Project[]> {
    this.werkmaatschappijen = await Werkmaatschappij.getLookup();
    return <Project[]> await super.dataGetter();
  }

  public readonly tableDefinition: DatatableDef = {
    standaardsortering: {
      key: 'naam',
      richting: 1
    },
    velden: {
      naam: {
        label: 'Naam'
      },
      leverancierstype: {
        label: 'Type',
        type: 'enum'
      },
      werkmaatschappij_id: {
        label: 'Werkmaatschappij', //gewijzigd  was eerst mvp_do, maar er zijn nu meer mogelijkheden
        type: 'enum',
        format: wmid => this.werkmaatschappijen[wmid]?.afkorting
      },
    }
  };
}
