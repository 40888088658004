/*
* werkbon.ts
* 1-3-2023 - Jelmer Jellema - Spin in het Web B.V.
* Model voor werkbon-tabel: ondertekenen van uitgevoerde diensten
*/

import {Basemodel, ModelField} from "./basemodel";
import {PersoneelPlanitem} from './personeel_planitem';
import {Planitem} from './planitem';

export class Werkbon extends Basemodel {
  static override table = `werkbon`;

  //eigenaar is PersoneelPlanitem
  @ModelField() id: number;
  @ModelField() personeelvast_planitem_id: number;
  @ModelField() status: 'ingediend' | 'geaccepteerd' | 'gefactureerd' = 'ingediend';
  @ModelField() starttijd: string;
  @ModelField() eindtijd: string;
  @ModelField() pauze: number = 0; //minuten
  @ModelField() kilometers: number = 0; //INT, projectkm's
  @ModelField() kilometers_ww: number = 0; //INT, woonwerk-km's
  @ModelField() opmerkingen: string = '';
  @ModelField() proforma: boolean = false; //proforma = niet ondertekend
  @ModelField() ondertekenaar: string = null;
  @ModelField() handtekening: string = null; //data-url voor png
  @ModelField() extradocument: string = null; //en de dataurl voor een extra foto
  @ModelField() createdAt: string = null;

  /**
   * cast de owner
   */
  get personeelPlanitem(): PersoneelPlanitem
  {
    return (<PersoneelPlanitem> this._owner);
  }

  /**
   * Geef de bijbehorende planitem terug, als die er is
   */
  get planitem(): Planitem {
    return this.personeelPlanitem?.planitem;
  }

  /**
   * geeft true als de tijden anders zijn dan owner
   */
  get afwijkend(): boolean {
    return this.planitem &&
      (
        this.planitem.starttime !== this.starttijd ||
        this.planitem.endtime !== this.eindtijd
      );
  }
}
