<comment>globale styling in schermen.globals.scss, want per component werkt het niet ivm encapsulation</comment>
<datatable2 #datatable [definition]="tableDefinition" [data]="tableData" stickyheaders
            (rijklik)="edit($event.id)"></datatable2>
<div class="footer">
  <div links>{{ datatable.filterRijen }} / {{ datatable.rijen }}
    <ng-container *ngIf="datatable.selectieRijen"> Geselecteerd: {{ datatable.selectieRijen }}</ng-container>
    <div class="d-inline-block ml-4">
      <select [id]="id('periode')" class="form-control form-control-sm" name="periode" [(ngModel)]="datumbereik"
              aria-label="Kies een periode"
              (change)="switchPeriode()">
        <option *ngFor="let periode of datumBereikLijst" [ngValue]="periode.weken">{{ periode.waarde }}</option>
      </select>
    </div>
  </div>
  <div rechts>
    <button class="btn btn-outline-light ml-2" *ngIf="dataGewijzigd"
            title="De gegevens zijn inmiddels gewijzigd. Herlaad de data in dit scherm door op deze knop te klikken."
            (click)="reloadData()"><span class="fas fa-sync"></span></button>
    <ng-container *ngIf="api.can('werkbon','full')">
      <button type="button" class="btn btn-outline-light ml-2 selecteer-ok"
              (click)="datatable.selecteerRijclass('missend')">Selecteer zonder werkbon
      </button>
      <button type="button" class="btn btn-outline-light ml-2 selecteer-ok"
              (click)="datatable.selecteerRijclass('inkoop')">Selecteer inkoop
      </button>
      <button type="button" class="btn btn-outline-light ml-2 selecteer-ok" (click)="datatable.selecteerRijclass('ok')">
        Selecteer OK
      </button>
      <button type="button" class="btn btn-outline-light ml-2" [disabled]="! kanWerkbonpdf" (click)="werkbonPDF()">
        WerkbonPDF
      </button>
      <button type="button" class="btn btn-outline-light ml-2" [disabled]="! kanProforma" (click)="maakProforma()">Maak
        proforma
      </button>
      <button type="button" class="btn btn-outline-light ml-2" [disabled]="! kanInkoopOrder" (click)="doeInkoop()">
        Inkoop
      </button>
      <button type="button" class="btn btn-outline-light ml-2" [disabled]="! kanIndienen" (click)="doeIndienen()">
        Indienen
      </button>
    </ng-container>
  </div>
</div>
