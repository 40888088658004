/*
* werkbonnen.module.ts
* 1-3-2023 - Jelmer Jellema - Spin in het Web B.V.
*/

import {NgModule} from '@angular/core';
import {BasisModule} from '../../app/basis.module';
import {Werkbonnenlijstscherm} from './werkbonnenlijst';
import {SihwDatatable2Module} from 'sihw-ng/datatable2/datatable2.module';

const schermen = [
  Werkbonnenlijstscherm
];

@NgModule({
  declarations: schermen,
  imports: [
    BasisModule,
    SihwDatatable2Module
  ],
  exports: schermen
})
export class WerkbonnenModule {
}

Werkbonnenlijstscherm.register('Werkbonnenlijstscherm');
